/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/require-default-props */
import React from "react";
import Carousel from "react-multi-carousel";
import { DotMatrixIconSvg, SECTION_IDS } from "@Utils";
import { BiChevronLeft } from "@react-icons/all-files/bi/BiChevronLeft";
import { BiChevronRight } from "@react-icons/all-files/bi/BiChevronRight";
import useMediaQuery from "@restart/hooks/useMediaQuery";
import { ITemplate } from "@Templates/ITemplate";
import { TestimonialCard } from "./components/testimonialCard";
import style from "./Testimonials.module.scss";

const responsive = {
    large: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 951 },
        items: 2,
    },
    small: {
        breakpoint: { max: 950, min: 0 },
        items: 1,
    },
};

export const Testimonial = ({
    hideComponent,
    title,
    testimonialsData,
    onTestimonialClick,
    pauseTestimonialsAutoplay,
}: ITemplate.ITestimonialsSectionProps): JSX.Element | null => {
    const numberOfTestimonialsPerSlide = useMediaQuery(
        "(max-device-width: 950px)"
    )
        ? 1
        : 2;

    const ButtonGroup = ({
        next,
        previous,
    }: {
        next?: () => void;
        previous?: () => void;
    }) => {
        if (testimonialsData.length > numberOfTestimonialsPerSlide) {
            return (
                <div className="carousel-button-group">
                    <div className={style.controls}>
                        <div
                            className={style.arrowStyle}
                            onClick={() => {
                                previous?.();
                            }}
                        >
                            <BiChevronLeft />
                        </div>
                        <div
                            className={style.arrowStyle}
                            onClick={() => {
                                next?.();
                            }}
                        >
                            <BiChevronRight />
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    if (hideComponent) return null;

    return (
        <div className={style.testimonialtemplateContainer}>
            <a
                id={SECTION_IDS.testimonials}
                className={style.anchorTestimonial}
            />
            <div className={style.testimonialtemplateSubContainer}>
                <div className={style.testimonialTitle}>
                    {title || "Testimonials"}
                </div>
                <div className={style.horizontalBar} />
            </div>
            <div className={style.testimonialTemplate}>
                <div className={style.carousalItems}>
                    <Carousel
                        responsive={responsive}
                        infinite
                        renderButtonGroupOutside
                        autoPlay={
                            testimonialsData.length >
                                numberOfTestimonialsPerSlide &&
                            !pauseTestimonialsAutoplay
                        }
                        arrows={false}
                        showDots={false}
                        customButtonGroup={<ButtonGroup />}
                    >
                        {testimonialsData.map(item => (
                            <TestimonialCard
                                data={item}
                                key={item.uuid}
                                onTestimonialClick={e =>
                                    onTestimonialClick(e, item)
                                }
                            />
                        ))}
                    </Carousel>
                </div>
                <DotMatrixIconSvg className={style.dotMatrix} />
            </div>
        </div>
    );
};

import React from "react";
import {
    ImagePreviewIconSvg,
    QuoteBeginIconSvg,
    TESTIMONIAL_DATA_TYPE,
    trimText,
} from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import useMediaQuery from "@restart/hooks/cjs/useMediaQuery";
import { ITemplate } from "@Templates/ITemplate";
import VideoPlayer from "repoV2/components/common/VideoPlayer/VideoPlayer";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import style from "./TestimonialCard.module.scss";

export const TestimonialCard = ({
    data,
    onTestimonialClick,
}: ITemplate.ITestimonialCardProps): JSX.Element => {
    const isDesktopOrLaptop = useMediaQuery("(min-device-width: 500px)");

    const ProfileSection = (): JSX.Element => {
        return (
            <div className={style.profileSection}>
                <ExlyImage
                    fetchWidth={92}
                    className={style.testimonialprofileImg}
                    src={data?.cover_image}
                    loading="lazy"
                    alt="profile"
                />
                <div className={style.profileName}>
                    {trimText(data.name, 30)}
                </div>
            </div>
        );
    };

    if (data?.type === TESTIMONIAL_DATA_TYPE.VIDEO) {
        return (
            <div className={style.testimonialCardContainer}>
                <div
                    className={classNames(
                        style.testimonialSubContainer,
                        style.mediaFeedbackContainer
                    )}
                >
                    <div
                        className={style.mediaContainer}
                        onClick={onTestimonialClick}
                    >
                        <VideoPlayer
                            videoUrl={data.media}
                            light
                            optimizeUsingThumbnail
                            width="100%"
                            height="268px"
                        />
                        <div className={style.mediaOverlay} />
                    </div>
                    <ProfileSection />
                </div>
            </div>
        );
    }

    if (data?.type === TESTIMONIAL_DATA_TYPE.IMAGE) {
        return (
            <div className={style.testimonialCardContainer}>
                <div
                    className={classNames(
                        style.testimonialSubContainer,
                        style.mediaFeedbackContainer
                    )}
                >
                    <div
                        className={style.mediaContainer}
                        onClick={onTestimonialClick}
                    >
                        <ExlyImage
                            fetchWidth={500}
                            alt={data.content}
                            src={data.media}
                        />
                        <div
                            className={classNames(
                                style.mediaOverlay,
                                style.imageOverlay
                            )}
                        >
                            <ImagePreviewIconSvg height="40px" />
                        </div>
                    </div>
                    <ProfileSection />
                </div>
            </div>
        );
    }

    const maxlength: number = isDesktopOrLaptop ? 260 : 150;
    const showReadMore: boolean = !!(
        data?.content && data.content.length > maxlength
    );

    return (
        <div className={style.testimonialCardContainer}>
            <div className={style.testimonialSubContainer}>
                <QuoteBeginIconSvg className={style.quote} />
                <div className={style.testimonialCardTitle}>
                    {showReadMore
                        ? `${data.content.slice(0, maxlength)}... `
                        : data.content}
                    {showReadMore ? (
                        <a
                            role="button"
                            tabIndex={-1}
                            onKeyDown={() => null}
                            onClick={onTestimonialClick}
                            className={style.readMore}
                        >
                            Read More
                        </a>
                    ) : null}
                </div>
                <ProfileSection />
            </div>
        </div>
    );
};
